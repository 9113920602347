.image-comparison-wrapper {
  display: inline-block; /* Wyświetlanie obrazów w rzędzie */
  margin: 10px; /* Margines wokół każdego obrazu */
}

.thumbnail-image {
  width: 400px; /* Stała szerokość miniatury */
  height: 300px; /* Stała wysokość miniatury */
  max-width: 30vw; /* Maksymalna szerokość */
  max-height: 30vh; /* Maksymalna wysokość */
  object-fit: cover; /* Przycięcie obrazu, aby pasował */
  cursor: pointer; /* Wskaźnik kliknięcia */
  transition: transform 0.2s; /* Płynny efekt zoomu */
}

.thumbnail-image:hover {
  transform: scale(1.05); /* Powiększenie na hover */
}

.image-comparison-container {
  position: fixed;
  top: 10%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 80%;
  max-width: 1000px;
  max-height: 90vh;
  height: 700px;
  background-color: rgba(0, 0, 0, 0.9); /* Ciemne tło */
  padding: 20px; /* Padding wokół zawartości */
  z-index: 1000; /* Ustaw na górze */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5); /* Efekt cienia */
  border-radius: 8px; /* Zaokrąglone rogi */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.comparison-images {
  position: relative; /* Ustawienie pozycji relatywnej */
  width: 100%; /* Pełna szerokość */
  height: 100%; /* Pełna wysokość */
  max-width: 95vw; /* Maksymalna szerokość */
  max-height: 95vh; /* Maksymalna wysokość */
  overflow: hidden; /* Ukryj nadmiar */
}

.image-comparison-before,
.image-comparison-after {
  position: absolute; /* Pozycja absolutna, aby zdjęcia były nałożone na siebie */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Wypełnienie kontenera */
}

.image-comparison-before {
  z-index: 1; /* Pod zdjęciem "po" */
}

.image-comparison-after {
  z-index: 2; /* Nad zdjęciem "przed" */
}

.image-comparison-after-img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-comparison-divider {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 4px; /* Szerokość linii suwaka */
  background-color: rgba(255, 255, 255, 0.8); /* Kolor linii suwaka */
  cursor: ew-resize; /* Kursor zmiany rozmiaru */
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3; /* Nad obrazami */
}

.image-comparison-close {
  margin-top: 10px;
  cursor: pointer;
  background: #ff5555;
  color: white;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  z-index: 5; /* Nad obrazami */
}

.image-comparison-label {
  position: absolute;
  font-size: 18px; /* Zwiększ rozmiar czcionki */
  font-weight: bold; /* Zmień na pogrubioną czcionkę */
  color: white; /* Kolor tekstu */
  background-color: rgba(0, 0, 0, 0.6); /* Półprzezroczyste tło dla lepszej czytelności */
  padding: 5px 10px; /* Dodaj padding wokół tekstu */
  border-radius: 5px; /* Zaokrąglone rogi */
  z-index: 4; /* Nad obrazami */
}

.image-comparison-label-before {
  top: 10px; /* Odstęp od góry */
  left: 10px; /* Odstęp od lewej */
}

.image-comparison-label-after {
  top: 10px; /* Odstęp od góry */
  right: 10px; /* Odstęp od prawej */
}