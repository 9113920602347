/* Gallery.css */

/* Główny kontener galerii */
.gallery-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background-color: var(--card-background-color); /* Ujednolicone tło */
  border-radius: 15px; /* Ujednolicone zaokrąglenia */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Ujednolicony cień */
  color: var(--text-color);
  transition: transform 0.3s, box-shadow 0.3s;
}

.gallery-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Sekcja informacyjna na górze galerii */
.gallery-info {
  background-color: var(--nav-background-color); /* Zostawiamy dla kontrastu */
  color: var(--text-color);
  padding: 15px;
  margin-bottom: 20px;
  border-radius: 10px; /* Ujednolicone zaokrąglenia */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Ujednolicony cień */
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.gallery-info .icon {
  font-size: 1.5em;
  color: var(--text-color);
}

.gallery-container h2 {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.2em;
  font-weight: bold;
  color: var(--text-color);
}

/* Sekcja porównawcza */
.comparison-section {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

/* Stylizacja pojedynczej metamorfozy */
.image-comparison {
  flex: 1 1 45%;
  max-width: 400px; /* Opcjonalnie ograniczenie szerokości */
  margin: 10px;
}

/* Stylizacja karuzeli (jeśli używasz) */
.carousel-container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.carousel-slide {
  width: 100%;
  display: flex; /* Dodajemy flexbox */
  justify-content: center; /* Wyśrodkowanie zawartości */
  align-items: center; /* Wyśrodkowanie pionowe, jeśli potrzebne */
}

/* Stylizacja komponentu ImageComparison wewnątrz karuzeli */
.carousel-slide .image-comparison {
  max-width: 100%;
  display: flex;
  justify-content: center; /* Wyśrodkowanie wewnątrz ImageComparison */
}

.image-comparison img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto; /* Wyśrodkowanie obrazów */
}

.carousel-controls {
  position: absolute;
  top: 50%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  transform: translateY(-50%);
}

.carousel-controls button {
  background: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  font-size: 2em;
  padding: 0.2em 0.5em;
  cursor: pointer;
}

.carousel-controls button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.carousel-controls button:focus {
  outline: none;
}
