/* App.css */

/* Zmienna CSS dla kolorów */


body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

/* Zawartość aplikacji */
.App-content {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
}

:root {
  --background-color: #ffffff;
  --text-color: #000000;
  --nav-background-color: #f0f0f0;
  --header-background-color: #f0f0f0;
  --table-background-color: #ffffff; /* Tło tabeli dla jasnego motywu */
  --table-header-color: #f0f0f0; /* Tło nagłówka tabeli dla jasnego motywu */
  --table-text-color: #000000; /* Kolor tekstu dla jasnego motywu */
}

body.dark-mode {
  --background-color: #121212;
  --text-color: #ffffff;
  --nav-background-color: #1e1e1e;
  --header-background-color: #1e1e1e;
  --table-background-color: #1e1e1e; /* Tło tabeli dla ciemnego motywu */
  --table-header-color: #333333; /* Tło nagłówka tabeli dla ciemnego motywu */
  --table-text-color: #ffffff; /* Kolor tekstu dla ciemnego motywu */
}

/* Dodaj style dla cennika */
.pricing-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--nav-background-color);
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: var(--text-color);
}

.pricing-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2em;
  font-weight: bold;
}

.pricing-category {
  margin-bottom: 20px;
}

.pricing-header {
  font-size: 1.5em;
  background-color: var(--table-header-color);
  padding: 10px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pricing-header:hover {
  background-color: #e0e0e0; /* Kolor po najechaniu */
}

.pricing-details {
  padding: 15px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-top: 10px;
  transition: max-height 0.5s ease; /* Płynne rozwijanie */
  overflow: hidden; /* Ukrycie zawartości wychodzącej poza obszar */
  height: auto; /* Dynamiczna wysokość na podstawie zawartości */
}

.expand {
  max-height: none; /* Usuń ograniczenie wysokości */
  height: auto; /* Pozwól na dynamiczne rozszerzenie sekcji */
}

.pricing-overview {
  font-weight: bold;
  margin-bottom: 15px;
}

.pricing-subcategory {
  margin-bottom: 15px;
}

.pricing-subcategory h4 {
  font-size: 1.2em;
  margin-bottom: 10px;
}

.pricing-item {
  margin-bottom: 10px;
}

.pricing-details ul {
  list-style-type: disc; /* Typ wypunktowania */
  margin-left: 20px; /* Margines dla struktury */
}

.pricing-details ul.sub-list {
  margin-left: 40px; /* Większe wcięcie dla podlist */
  list-style-type: circle; /* Typ wypunktowania dla podlist */
}

.pricing-details ul li {
  margin-bottom: 10px; /* Odstęp między elementami listy */
}

.contact-container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: var(--background-color);
  color: var(--text-color);
}

.contact-details {
  display: flex;
  flex-direction: column;
  gap: 10px; /* Odstępy między danymi kontaktowymi */
}

