/* Header.css */

/* Ogólny styl nagłówka */
.app-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 10px 30px;
  background-color: var(--header-background-color);
  gap: 90px;
  flex-wrap: wrap;
  position: relative;
}

/* Menu nawigacyjne */
.app-nav {
  font-size: 1.2em;
  display: flex;
  align-items: center;
  gap: 10px;
}

.app-nav ul {
  display: flex;
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.app-nav ul li {
  margin: 0 10px;
}

.app-nav ul li a {
  text-decoration: none;
  color: var(--text-color);
}

.app-nav ul li a:hover {
  text-decoration: underline;
}

/* Sekcja z informacjami kontaktowymi */
.header-right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

/* Informacje kontaktowe */
.contact-details {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; /* Pozwala na zawijanie wierszy, jeśli zabraknie miejsca */
  align-items: flex-start;
  gap: 20px; /* Odstęp między elementami */
}

.header-info {
  display: flex;
  align-items: center;
  font-size: 1em;
  color: var(--text-color);
}

.header-info a {
  color: inherit; /* Use the same color as the parent element */
  text-decoration: none; /* Remove underline */
}

.header-info a:hover {
  color: var(--accent-color); /* Optional: Change color on hover */
  text-decoration: underline; /* Optional: Add underline on hover */
}

/* Kontener dla logo i tekstu */
.header-container {
  position: relative;
  display: flex;
  justify-content: space-between; /* Odstęp między logo a tekstem */
  align-items: flex-start; /* Ustawia elementy w jednej linii */
  padding: 20px; /* Dodaje wewnętrzny padding */
}

/* Stylowanie logo */
.app-logo {
  height: 180px;
  width: auto;
  cursor: pointer;
  margin: 0;
}

/* Obszar informacji o usługach */
.service-area {
  font-size: 1.5em;
  font-style: italic;
  color: var(--text-color);
  text-align: left;
  line-height: 1.4;
  margin: 0;
  padding-right: 40px; /* Odsunięcie tekstu od logo */
}

.service-area strong {
  font-weight: bold;
  display: block;
}

/* Ikony w nagłówku */
.header-icon {
  margin-right: 8px;
  min-width: 20px; /* Dodajemy minimalną szerokość, aby ikony były wyrównane */
  color: var(--text-color);
  text-align: center; /* Wyśrodkowanie ikon w pionie */
}

/* Kontrolki w nagłówku */
.header-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

.theme-toggle-button {
  background: none;
  border: none;
  cursor: pointer;
  color: var(--text-color);
  font-size: 2.5em;
}

.language-switch {
  display: flex;
  gap: 10px;
}

.flag-button {
  background-color: var(--icon-background-color);
  border: none;
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.flag-button {
  font-size: 2.0em;
}

/* Dropdown Menu Styles */
.dropdown {
  position: relative;
}

.dropdown-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1em;
  color: var(--text-color);
  padding: 0;
  text-align: left;
  width: 100%;
}

.dropdown-menu {
  position: absolute;
  left: 0;
  top: 100%;
  background: var(--nav-background-color);
  border: 1px solid #ccc;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  list-style: none;
  padding: 10px 0;
  margin: 0;
  z-index: 1000;
  width: 300px;
  display: block;
  flex-direction: column;
  border-radius: 8px;
  box-sizing: border-box;
}

.dropdown-menu li {
  padding: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  box-sizing: border-box;
}

.dropdown-menu li a {
  text-decoration: none;
  color: var(--text-color);
  display: block;
  width: 100%;
  padding: 10px 5px;
  box-sizing: border-box;
}

.dropdown-menu li a:hover {
  background-color: #e0e0e0;
}

/* Stylizacja linku do Facebooka */
.social-link {
  text-decoration: none;
  color: var(--text-color);
}

.social-link:hover {
  text-decoration: underline;
}

/* Przycisk menu mobilnego */
.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  font-size: 2em;
  cursor: pointer;
  color: var(--text-color);
  margin-top: 10px;
  display: none; /* Domyślnie ukryty */
}

/* --- DODANE STYLE DLA RESPONSYWNOŚCI --- */

/* Media Query dla ekranów o szerokości maksymalnej 768px */
@media (max-width: 768px) {
  /* Pokaż przycisk hamburgera na małych ekranach */
  .mobile-menu-button {
    display: block;
    margin: 0; /* Usunięto marginesy */
  }

  /* Ukryj menu nawigacyjne domyślnie na małych ekranach */
  .app-nav {
    display: none;
    flex-direction: column;
    align-items: center;
    width: 100%;
    background-color: var(--header-background-color);
    margin-top: 10px; /* Opcjonalnie dodaj margines górny */
  }

  /* Pokaż menu, gdy jest otwarte */
  .app-nav.open {
    display: flex;
  }

  /* Dostosuj elementy listy nawigacyjnej */
  .app-nav ul {
    flex-direction: column;
    align-items: center;
  }

  .app-nav ul li {
    margin: 10px 0;
  }

  /* Dostosuj wysokość logo na małych ekranach */
  .app-logo {
    height: 120px;
    margin: 0; /* Usunięto marginesy */
  }

  /* Dostosuj nagłówek */
  .app-header {
    flex-direction: column;
    align-items: center;
    gap: 5px; /* Zmniejszono wartość gap */
  }

  /* Dostosuj sekcję z informacjami kontaktowymi */
  .header-right {
    align-items: center;
  }

  .contact-details {
    align-items: flex-start;
  }

  /* Wyrównanie ikon i tekstu */
  .header-info {
    display: flex;
    align-items: center;
  }

  .language-switch {
    display: flex;
    gap: 10px;
  }

  .header-icon {
    margin-right: 8px;
    min-width: 20px;
    text-align: center;
  }

  .header-controls {
    display: flex;
    align-items: center;
    gap: 10px; /* Dostosuj odstęp według potrzeb */
    margin-top: 10px;
  }
  
  .theme-toggle-button {
    background-color: var(--icon-background-color);
    border: none;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--text-color);
    font-size: 2.1em; /* Ustaw taki sam rozmiar jak w .flag-button */
  }
  
  .flag-button {
    background-color: var(--icon-background-color);
    border: none;
    padding: 5px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .flag-button {
    font-size: 2.0em; /* Upewnij się, że rozmiar jest taki sam jak w .theme-toggle-button */
  }
  /* Dostosuj obszar informacji o usługach */
  .service-area {
    position: static;
    padding: 0;
    text-align: center;
    font-size: 1.2em;
    margin: 40px 0 0 0; /* Dodajemy margines górny 5px */
  }

  /* Usunięcie marginesów z elementów tekstowych wewnątrz service-area */
  .service-area p,
  .service-area strong {
    margin: 0;
  }
}
