/* Home.css */

/* Kolory bazowe definiowane w zależności od trybu */
:root {
  --background-color: #ffffff; /* Kolor tła w trybie jasnym */
  --text-color: #000000; /* Kolor tekstu w trybie jasnym */
  --primary-color: #333333; /* Nowy podstawowy kolor dla nagłówków i przycisków */
  --secondary-color: #ffffff; /* Tekst "Wkrótce" na biało */
}

body.dark-mode {
  --background-color: #121212; /* Kolor tła w trybie ciemnym */
  --text-color: #ffffff; /* Kolor tekstu w trybie ciemnym */
  --primary-color: #cccccc; /* Nowy podstawowy kolor dla nagłówków i przycisków */
  --secondary-color: #ffffff; /* Tekst "Wkrótce" na biało */
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
  transition: background-color 0.3s, color 0.3s;
}

.home-container {
  text-align: center;
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.intro-text {
  text-align: left;
  max-width: 800px;
  margin: 0 auto 40px;
  line-height: 1.6;
  color: var(--text-color); /* Kolor tekstu zależny od trybu */
}

.intro-text h2 {
  text-align: center;
  font-size: 2.5em;
  margin-bottom: 20px;
  color: var(--text-color); /* Zmieniono kolor nagłówka na kolor tekstu */
}

.offer-title {
  margin-top: 30px;
  font-size: 1.8em;
  color: var(--text-color); /* Kolor tekstu zależny od trybu */
  text-align: center;
}

.tiles-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 20px;
  margin: 40px 0;
}

.service-tile {
  position: relative;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s, box-shadow 0.3s;
  cursor: pointer;
  text-decoration: none; /* Usuń podkreślenie tekstu */
  color: inherit; /* Dziedziczenie koloru tekstu */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.service-tile:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.unavailable {
  filter: grayscale(80%);
  cursor: not-allowed;
}

.service-content {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  color: #ffffff; /* Tekst zawsze biały */
  padding: 15px;
  text-align: center;
  transition: background 0.3s;
}

.service-content:hover {
  background: rgba(0, 0, 0, 0.85);
}

.service-title {
  font-size: 1.2em;
  margin: 0;
}

.coming-soon {
  color: var(--secondary-color); /* Zmieniono na biały */
  font-size: 1em;
  margin-top: 10px;
}

.details-link {
  margin-top: 10px;
  padding: 8px 16px;
  background-color: var(--primary-color); /* Nowy kolor przycisku */
  color: #ffffff; /* Tekst zawsze biały */
  text-decoration: none;
  border-radius: 5px;
  border: none;
  cursor: pointer;
}

.details-link:hover {
  background-color: #555555; /* Subtelna zmiana koloru przycisku po najechaniu */
}

.additional-info {
  max-width: 800px;
  margin: 40px auto;
  text-align: left;
  line-height: 1.6;
  color: var(--text-color); /* Kolor tekstu zależny od trybu */
}

.contact-link {
  color: var(--primary-color); /* Nowy podstawowy kolor */
  font-weight: bold;
  text-decoration: none;
}

.contact-link:hover {
  text-decoration: underline;
}
