/* UpholsteryCleaning.css */

.upholstery-cleaning-container {
    display: flex;
    flex-wrap: wrap;
    margin: 10px;
    align-items: flex-start;
  }
  
  .upholstery-cleaning-content {
    flex: 1 1 55%;
    padding: 10px;
    box-sizing: border-box;
  }
  
  .upholstery-cleaning-content h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
  }

  .upholstery-cleaning-content h3 {
    font-size: 1.2em; /* Rozmiar czcionki */
    margin-top: 0.1em;   /* Możesz dostosować górny margines według potrzeb */
    margin-bottom: 0;  /* Ustawienie dolnego marginesu na zero */
  }
  
  .upholstery-cleaning-content ul {
    list-style-type: disc;
    padding-left: 20px;
    margin-top: 0;        /* Ustawienie marginesu górnego na zero */
    margin-bottom: 0.1em; /* Istniejący margines dolny */
  }
  
  .upholstery-cleaning-content ul li {
    margin-bottom: 0.1em; /* Zmniejszony odstęp między elementami listy */
  }

  .upholstery-cleaning-content p {
    font-size: 1.1em;
    line-height: 1.5;
    white-space: pre-line;
    margin-bottom: 0.5em;
  }
  
  .upholstery-cleaning-image {
    flex: 1 1 45%;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: flex-start;
  }
  
  .upholstery-cleaning-image img {
    display: block;
    max-width: 100%;
    height: auto;
    border-radius: 10px;
  }
  
  /* Dostosowanie dla urządzeń mobilnych */
  @media (max-width: 768px) {
    .upholstery-cleaning-container {
      flex-direction: column;
    }
  
    .upholstery-cleaning-content,
    .upholstery-cleaning-image {
      flex: 1 1 100%;
    }
  
    .upholstery-cleaning-content {
      order: 2; /* Opcjonalnie: tekst poniżej obrazu na małych ekranach */
    }
  
    .upholstery-cleaning-image {
      order: 1;
    }
  }
  