/* HomeCleaning.css */

.home-cleaning-container {
  display: flex;
  flex-wrap: wrap;
  margin: 10px; /* Zmniejszony margines */
  align-items: flex-start; /* Wyrównanie elementów do góry */
}

.home-cleaning-content {
  flex: 1 1 55%; /* Tekst nieco większy niż obraz */
  padding: 10px; /* Zmniejszony padding */
  box-sizing: border-box;
}

.home-cleaning-content h2 {
  font-size: 1.5em; /* Zmniejszony rozmiar czcionki */
  margin-bottom: 10px; /* Zmniejszony margines */
}

.home-cleaning-content p {
  font-size: 1.1em; /* Zmniejszony rozmiar czcionki */
  line-height: 1.2; /* Zmniejszona wysokość linii */
  white-space: pre-line; /* Zachowanie formatowania w tekście */
  margin-bottom: 0.1em; /* Zmniejszony margines */
}

/* Stylizacja nagłówków h3 */
.home-cleaning-content h3 {
  font-size: 1.2em; /* Rozmiar czcionki */
  margin-top: 0.1em;   /* Możesz dostosować górny margines według potrzeb */
  margin-bottom: 0;  /* Ustawienie dolnego marginesu na zero */
}

.home-cleaning-content ul {
  list-style-type: disc;
  padding-left: 20px;
  margin-top: 0;        /* Ustawienie marginesu górnego na zero */
  margin-bottom: 0.1em; /* Istniejący margines dolny */
}

.home-cleaning-content ul li {
  margin-bottom: 0.1em; /* Zmniejszony odstęp między elementami listy */
}

/* Stylizacja pogrubionego tekstu */
.home-cleaning-content strong {
  font-weight: bold;
}

.home-cleaning-image {
  flex: 1 1 45%; /* Obraz nieco mniejszy niż tekst */
  padding: 10px; /* Zmniejszony padding */
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start; /* Wyrównanie obrazu do góry */
}

@media (max-width: 768px) {
  .home-cleaning-container {
    flex-direction: column;
  }

  .home-cleaning-content,
  .home-cleaning-image {
    flex: 1 1 100%;
  }

  .home-cleaning-content {
    order: 2; /* Opcjonalnie: tekst poniżej obrazu na małych ekranach */
  }

  .home-cleaning-image {
    order: 1;
  }

  /* Dostosowanie marginesów i paddingu na mniejszych ekranach */
  .home-cleaning-content,
  .home-cleaning-image {
    padding: 5px;
  }

  .home-cleaning-content h2 {
    font-size: 1.3em;
    margin-bottom: 8px;
  }

  .home-cleaning-content p,
  .home-cleaning-content ul,
  .home-cleaning-content li {
    font-size: 0.9em;
    line-height: 1.2;
    margin-bottom: 0.1em;
  }

  .home-cleaning-content h3 {
    font-size: 1em;
    margin-top: 0.1em;
    margin-bottom: 0.1em;
  }
}

/* Nowe style dla cennika i galerii obok siebie */
.pricing-and-gallery {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start; /* Wyrównanie do góry */
  margin: 20px 10px;
}

.pricing-section,
.gallery-section {
  flex: 1 1 100%;
  box-sizing: border-box;
  padding: 10px;
}
.home-cleaning-image img {
  display: block;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}
/* Dostosowanie dla urządzeń mobilnych */
@media (max-width: 768px) {
  .pricing-and-gallery {
    flex-direction: column;
  }

  .pricing-section,
  .gallery-section {
    flex: 1 1 100%;
    padding: 5px;
  }
  .pricing-and-gallery {
    margin-bottom: 50px; /* Dostosuj wartość według potrzeb */
  }
}


