/* Contact.css */

.contact-container {
  max-width: 900px; /* Zwiększona maksymalna szerokość */
  margin: 40px auto; /* Większy odstęp nad i pod */
  padding: 30px; /* Większy padding dla przestronności */
  background-color: var(--background-color); /* Dynamiczne tło */
  color: var(--text-color); /* Dynamiczny kolor tekstu */
  border-radius: 15px; /* Zaokrąglenie rogów */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1); /* Subtelny cień */
}

.contact-info-container {
  display: flex; /* Flexbox dla układu w poziomie */
  justify-content: space-between; /* Przestrzeń między sekcjami */
  gap: 20px; /* Odstęp między sekcjami */
  flex-wrap: wrap; /* Zawijanie na mniejszych ekranach */
}

.contact-info, .legal-info, .opening-hours {
  flex: 1; /* Elastyczna szerokość sekcji */
  margin-bottom: 30px; /* Odstęp pod każdą sekcją */
  padding: 25px; /* Większy padding dla lepszej czytelności */
  background-color: var(--nav-background-color); /* Dynamiczne tło dla sekcji */
  border-radius: 12px; /* Zaokrąglone rogi */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.08); /* Delikatny cień */
  transition: background-color 0.3s, box-shadow 0.3s; /* Animacja przejść */
}

.contact-info:hover, .legal-info:hover, .opening-hours:hover {
  background-color: var(--header-background-color); /* Zmiana tła przy najechaniu */
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Większy cień przy najechaniu */
}

.contact-info h3, .legal-info h3, .opening-hours h3 {
  font-size: 1.6em; /* Większy rozmiar czcionki nagłówka */
  margin-bottom: 20px; /* Większy odstęp pod nagłówkiem */
  font-weight: bold; /* Pogrubiony nagłówek */
  color: var(--text-color); /* Dynamiczny kolor nagłówka */
  text-align: left; /* Wyrównanie do lewej */
  border-bottom: 2px solid rgba(0, 0, 0, 0.1); /* Delikatna linia pod nagłówkiem */
  padding-bottom: 10px; /* Odstęp pod linią */
}

.contact-info p, .legal-info p {
  margin: 10px 0; /* Odstępy między paragrafami */
  line-height: 1.8; /* Zwiększona wysokość linii dla lepszej czytelności */
  font-size: 1.1em; /* Większy rozmiar czcionki dla lepszej czytelności */
  color: var(--text-color); /* Dynamiczny kolor tekstu */
}

.opening-hours ul {
  list-style-type: none; /* Usuń styl listy */
  padding: 0; /* Brak paddingu */
}

.opening-hours li {
  margin: 8px 0; /* Większy odstęp między elementami listy */
  font-size: 1.1em; /* Większy rozmiar czcionki */
  color: var(--text-color); /* Dynamiczny kolor tekstu */
  padding: 8px 12px; /* Padding dla elementów listy */
  border-radius: 8px; /* Zaokrąglone rogi dla elementów listy */
}

.contact-info a, .legal-info a {
  color: var(--link-color); /* Dynamiczny kolor linku */
  text-decoration: none; /* Brak podkreślenia */
  transition: color 0.3s; /* Animacja zmiany koloru */
}

.contact-info a:hover, .legal-info a:hover {
  text-decoration: underline; /* Podkreślenie przy najechaniu */
  color: var(--text-color); /* Dynamiczny kolor przy najechaniu */
}
