/* Pricing.css */

/* Główna kontener cennika */
.pricing-container {
  max-width: 900px;
  margin: 40px auto;
  padding: 30px;
  background-color: var(--nav-background-color); /* Dynamiczne tło */
  border-radius: 15px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  color: var(--text-color); /* Dynamiczny kolor tekstu */
  transition: transform 0.3s, box-shadow 0.3s;
}

.pricing-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

/* Tytuł cennika */
.pricing-title {
  text-align: center;
  margin-bottom: 30px;
  font-size: 2.2em;
  font-weight: bold;
  color: var(--text-color); /* Dynamiczny kolor tekstu */
}

/* Kontener kategorii cennika */
.pricing-category {
  margin-bottom: 20px;
  background-color: var(--background-color); /* Dynamiczne tło dla kontrastu */
  border-radius: 10px;
  padding: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtelny cień dla kart */
  cursor: pointer;
  transition: background-color 0.3s, box-shadow 0.3s; /* Dodajemy animację dla cienia */
}

.pricing-category:hover {
  background-color: var(--header-background-color); /* Dynamiczne tło przy najechaniu */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Cień przy najechaniu */
}

/* Nagłówki w sekcjach cennika */
.pricing-header {
  font-size: 1.5em;
  font-weight: bold;
  color: var(--text-color); /* Dynamiczny kolor nagłówka */
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Tabela cennika */
.pricing-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  background-color: var(--background-color); /* Dodane tło dla tabeli */
  border-radius: 10px; /* Zaokrąglone rogi tabeli */
  overflow: hidden; /* Ukrywanie nadmiarowej zawartości */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Delikatny cień */
}

.pricing-table th,
.pricing-table td {
  padding: 120x;
  text-align: left;
  border-bottom: 1px solid var(--header-background-color); /* Dynamiczna dolna obwódka */
  color: var(--text-color); /* Dynamiczny kolor tekstu */
}

.pricing-table th {
  background-color: var(--nav-background-color); /* Dynamiczne tło nagłówków tabeli */
  font-weight: bold;
  color: var(--text-color); /* Dynamiczny kolor tekstu */
}

/* Stylizacja przycisków rozwijanych */
.expand {
  max-height: 1000px;
  transition: max-height 0.5s ease;
  overflow: hidden;
  background-color: var(--background-color); /* Dynamiczne tło dla sekcji rozwijanych */
  color: var(--text-color); /* Dynamiczny kolor tekstu dla sekcji rozwijanych */
  border: 1px solid var(--header-background-color); /* Dynamiczna obwódka */
  border-radius: 10px; /* Zaokrąglone rogi dla sekcji rozwijanych */
  margin-top: 10px; /* Odstęp nad sekcją rozwijaną */
  padding: 15px; /* Dodajemy padding dla przestronności */
}

.collapse {
  max-height: 0;
  overflow: hidden;
  background-color: var(--background-color); /* Utrzymanie tła także w stanie zwiniętym */
  color: var(--text-color); /* Dynamiczny kolor tekstu */
  border: 1px solid var(--header-background-color); /* Dynamiczna obwódka */
  border-radius: 10px; /* Zaokrąglone rogi */
  margin-top: 10px;
  padding: 0 15px; /* Padding 0, aby ukryć zawartość */
}

/* Przyciski akcji w cenniku */
.action-button {
  margin-top: 15px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #218838;
}

/* Sekcja płatności */
.payment-section {
  margin-top: 40px;
  margin-bottom: 40px;
  padding: 30px;
  background: linear-gradient(to bottom right, var(--nav-background-color), var(--background-color)); /* Dynamiczne gradientowe tło */
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: transform 0.3s, box-shadow 0.3s;
}

.payment-section:hover {
  transform: translateY(-5px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.payment-section h3 {
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 1.8em;
  color: var(--text-color); /* Dynamiczny kolor tekstu */
}

.payment-section p {
  margin: 10px 0;
  font-size: 1.1em;
  color: var(--text-color); /* Dynamiczny kolor tekstu */
  line-height: 1.8;
}

/* Stylizacja ikon */
.payment-section .icon {
  margin-right: 10px;
  vertical-align: middle;
  color: var(--text-color); /* Dynamiczny kolor ikon */
  font-size: 1.5em;
}

.payment-section:hover .icon {
  color: var(--text-color); /* Dynamiczny kolor ikon przy najechaniu */
}

/* Stylizacja dla trybu ciemnego */
body.dark-mode .pricing-category,
body.dark-mode .pricing-table,
body.dark-mode .payment-section,
body.dark-mode .expand,
body.dark-mode .collapse {
  background-color: #333; /* Tło dla ciemnego trybu */
  color: #f0f0f0; /* Jasny kolor tekstu */
  border: 1px solid #444; /* Obramowanie dla ciemnego trybu */
}

body.dark-mode .pricing-table th {
  background-color: #444; /* Tło nagłówków w tabeli dla ciemnego trybu */
}

body.dark-mode .pricing-table td {
  border-bottom: 1px solid #555; /* Obwódka komórek w tabeli dla ciemnego trybu */
}

/* Usuwa domyślne odstępy dla nieuporządkowanych list w pricing-details */
.pricing-details ul {
  list-style-type: none; /* Usuwa punktor listy */
  margin: 0; /* Usuwa marginesy */
  padding: 0; /* Usuwa wcięcia */
}

/* Dostosowuje styl elementów listy */
.pricing-details li {
  margin: 0; /* Usuwa marginesy */
  padding: 5px 0; /* Opcjonalnie dodaje odstęp pionowy między elementami */
}

/* Jeśli chcesz dodatkowo wyrównać tekst */
.pricing-details li,
.pricing-item,
.sub-list li {
  text-align: left; /* Upewnia się, że tekst jest wyrównany do lewej */
}

/* Dla podlisty (sub-list) */
.sub-list {
  list-style-type: none; /* Usuwa punktor */
  margin: 0; /* Usuwa marginesy */
  padding-left: 0; /* Usuwa wcięcie */
}

/* Jeśli chcesz dodać minimalne wcięcie dla podlisty */
.sub-list {
  padding-left: 10px; /* Dostosuj wartość według potrzeb */
}