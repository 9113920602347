/* Footer.css */

/* General Styling */
.app-footer {
  background-color: var(--header-background-color);
  color: var(--text-color);
  padding: 40px 20px 20px;
  font-size: 1em;
  position: relative;
}

.footer-content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1 1 30%;
  margin: 10px;
}

.footer-section h3 {
  font-size: 1.2em;
  margin-bottom: 15px;
}

.footer-contact p,
.footer-links ul,
.footer-social .social-icons {
  margin: 5px 0;
}

.footer-contact a {
  color: inherit; /* Use the same color as the parent element */
  text-decoration: none; /* Remove underline */
}

.footer-contact a:hover {
  color: var(--accent-color); /* Optional: Change color on hover */
  text-decoration: underline; /* Optional: Add underline on hover */
}

.footer-icon {
  margin-right: 10px;
  color: var(--accent-color);
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links ul li {
  margin-bottom: 10px;
}

.footer-links ul li a {
  color: var(--link-color); /* Dynamiczny kolor linku */
  text-decoration: none; /* Brak podkreślenia */
  transition: color 0.3s; /* Animacja zmiany koloru */
}

.footer-links ul li a:hover {
  color: var(--accent-color);
}

.footer-social .social-icons {
  display: flex;
  gap: 15px;
}

.social-icon {
  font-size: 1.5em;
  color: var(--text-color);
  transition: color 0.3s;
}

.social-icon:hover {
  color: var(--accent-color);
}

/* Footer Bottom */
.footer-bottom {
  text-align: center;
  margin-top: 30px;
  border-top: 1px solid var(--text-color);
  padding-top: 20px;
  font-size: 0.9em;
}

/* Responsive Design */
@media (max-width: 768px) {
  .app-footer {
    position: relative; /* Lub nie ustawiaj żadnego position */
  }
  
  .footer-content {
    flex-direction: column;
    align-items: center;
  }

  .footer-section {
    flex: 1 1 100%;
    text-align: center;
  }

  .footer-links ul li {
    display: inline-block;
    margin: 0 10px;
  }

  .footer-links ul {
    margin-top: 10px;
  }
}
